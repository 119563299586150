.a11y__interactive--button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.btn-webcast--bid {
  white-space: normal;
}

.auction__lots .lot__action {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.feed {
  &--centric {
    position: relative;
    > div:first-child {
      margin-bottom: 0;
    }
    div + .image-gallery {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      width: 30%;
      border: 0.5rem solid #fff;
      border-radius: 0.25rem;
      z-index: 1;
    }
  }
  &--floating {
    position: 'fixed';
    width: '45%';
    right: 0;
    top: 0;
    z-index: 1039;
  }
}

.media-body > h3:first-child {
  margin-top: 0;
}

.lot__info--bid-count-text,
.lot__info--bid-status {
  display: inline-block;
}

.auction__lots {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  > div {
    margin-bottom: 3rem;
  }
  .lot__info {
    flex-grow: 1;
    flex-shrink: 1;
    &--bid-count,
    &--bid-status {
      line-height: 1;
      vertical-align: baseline;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.auction-download__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
}

textarea {
  max-width: 100%;
}

.lot {
  overflow: hidden;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &--reduced {
    opacity: 0.3;
  }
  &__media {
    position: relative;
    flex-grow: 0;
    .image-gallery-thumbnail {
      width: 75px;
    }
    &--empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &--missing svg {
      font-size: 6rem;
    }
  }
  &.timed-listing .lot__media {
    .lot__number {
      position: absolute;
      bottom: 1rem;
      left: 0;
      padding: 0.5rem 1rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 5;
    }
    .timed-listing--grid-layout &--empty {
      .lot__number {
        position: static;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
      }
      .lot__contact {
        border-bottom-right-radius: 0px;
        border-top-left-radius: 0px;
        top: 0;
        bottom: 0;
      }
    }
  }
  &__contact {
    position: absolute;
    bottom: 1rem;
    right: 0;
    padding: 0.5rem 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 5;
  }
  &__flag {
    position: absolute;
    bottom: 1rem;
    right: 0;
    font-size: 2rem;
    padding: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 5;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 0;
    align-items: flex-end;
    > button {
      flex-grow: 1;
      white-space: normal;
    }
  }
  &__watch {
    margin-top: -1rem;
    margin-right: -1rem;
    padding: 1rem;
    opacity: 0.75;
    transition: all 0.25s;
    background-color: transparent;
    z-index: 5;
    svg {
      color: #fff;
      font-size: 2rem;
      overflow: visible;
      > path {
        transition: all 0.25s;
        fill: transparent;
        stroke: #333;
        stroke-width: 50;
      }
    }
    &:hover {
      opacity: 1;
      svg > path {
        fill: white;
        stroke: $rt-color-warning;
      }
    }
    &--watched {
      svg > path {
        fill: $rt-color-warning;
        stroke-width: 0;
      }
      &:hover {
        svg > path {
          fill: $rt-color-warning;
        }
      }
    }
  }
}

span.input-group-addon:empty {
  padding: 3px;
  visibility: hidden;
}

.lot-list--item .lot__watch svg {
  font-size: 1.5rem;
}

.bidjs-loading {
  opacity: 0.1;
}

.bg-muted {
  background-color: #eee;
}

.upcoming-auctions {
  &__list {
    margin-top: 3rem;
  }
  &-item {
    position: relative;
    margin-bottom: 4rem;
    &__badge {
      position: absolute;
      z-index: 2;
      top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      &--featured {
        left: 0;
        border-bottom-left-radius: 0;
      }
      &--type {
        right: 0;
        border-bottom-right-radius: 0;
      }
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: stretch;
      &--supplementary {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
      }
      &--textual {
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.upcoming-marketplaces__list {
  display: flex;
  flex-wrap: wrap;
  > .upcoming-marketplace-item {
    display: flex;
    flex-direction: column;
  }
}

.video--container {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

#sale-info__image--auction-logo {
  width: 100px;
  float: right;
}

.nav-tabs-responsive {
  overflow: auto;
  & + .card,
  & + .panel {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.social {
  &__label {
    display: inline-block;
    vertical-align: bottom;
    height: 32px;
  }
}

.carousel {
  overflow: hidden;
  position: relative;
  &__container {
    display: flex;
  }
  &__slide {
    position: relative;
  }
}

.embla__viewport {
  overflow: hidden;
}

.listing__single {
  &--actions {
    display: flex;
    flex: 1 1 33%;
    > .btn {
      white-space: normal;
      margin: 0;
    }
  }
}

.upcoming-auctions-item__images {
  margin-right: -1rem;
  .carousel__slide {
    padding-right: 1rem;
    width: 20%;
    min-width: 20%;
  }
}

.lot__supplementary--other-listings .carousel {
  .carousel__slide {
    width: 20%;
    min-width: 20%;
  }
}

.embla__container--thumb {
  display: flex;
  margin: 0 -0.25rem;
  .carousel__slide {
    margin-top: 0.5rem;
    padding: 0 0.25rem;
    width: 25%;
    min-width: 25%;
    opacity: 0.6;
    transition: opacity 0.5s;
    &--active {
      opacity: 1;
    }
  }
}

.embla--thumb-image:not(.embla--thumb-image__selected) {
  opacity: 0.8;
}

.listings__carousel--webcast.carousel,
.webcast__carousel--primary.carousel + .embla--thumb {
  .carousel__slide {
    width: 20%;
    min-width: 20%;
  }
}

.timed-listing .carousel,
.listings__carousel--primary,
.webcast__carousel--primary {
  .carousel__slide {
    width: 100%;
    min-width: 100%;
  }
}

.lot__country-flag {
  vertical-align: middle;
}

.lot__info--quantity {
  font-size: 85%;
}

.lot__timings {
  font-size: 85%;
  font-weight: 700;
  color: #777;
}

.page__loader {
  text-align: center;
}

.lot__badges {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  span {
    display: inline-block;
    line-height: 1.1;
    white-space: normal;
  }
  span + span {
    margin-left: 0.5rem;
  }
}

.timed-listing--single .lot__info--current-bid {
  font-size: 3rem;
}

// mt-0
#sale-info__title,
.alert__heading,
.lot__info--auction-title,
.lot__info--heading,
.lot__heading--category,
.lot__heading--title,
.lot__action,
.modal__primary-title,
.page__title,
.timed-auction__pagination--bottom,
.timed-heading__times,
.upcoming-auctions-item__heading {
  margin-top: 0;
}

// mt-1
.timed-auction__filter--icon {
  margin-top: 0.25rem;
}

// mt-2
.list-item__form-group,
#inplay__alert--sale-message,
.upcoming-auctions-item__title,
.upcoming-marketplace-item__title {
  margin-top: 0.5rem;
}

// mt-3
#app__branding,
.bid__cta,
.contact-seller__form,
.listing-downloads__list,
.listing__single--actions,
.lot__action--catalogue,
.timed-auction__pagination--top,
.timed-auction__filters--state,
.websocket__cta {
  margin-top: 1rem;
}

// mb-0
.bid-content,
.bid-history,
#inplay__alert--sale-message,
#inplay__content--summary,
.list-item__form-group,
.listing-downloads__list,
.lot__info--increment,
.lot__status--body,
.my-sales__table,
.my-sales-summary__title,
.sale-info__dl,
.timed-auction__pagination--top {
  margin-bottom: 0;
}

// mb-1
.lot__bid-status,
.lot__info--quantity {
  margin-bottom: 0.25rem;
}

// mb-2
.bid__amount,
.bid__registrant--location,
.bid__registrant--paddle,
.timed-auction__filters--state {
  margin-bottom: 0.5rem;
}

// mb-3
#auction__action--sale-info,
#auction__action--terms-info,
#auction__action--contact,
#auction__action--register,
.change-password__title,
#inplay__alert--actions,
.listing__single--other-actions,
.listing-details__imagery,
.listing-modal__heading,
.listing-single-nav,
.lot__supplementary--other-listings,
.modal__icon,
.page__title,
#sale-info__image--auction-logo,
.social__container,
.timed-auction__pagination--bottom,
.timed-listing--single,
.upcoming-auctions-item__badge,
.upcoming-auctions-item__content--textual,
.upcoming-auctions-item__heading {
  margin-bottom: 1rem;
}

// mb-n3
.lot__info--description-link,
.sale-info__dd--no-margin {
  margin-bottom: -1rem;
}

// ml-2
.bid-history__flag--user,
.filters__badge,
.lot__fee--bp-tax,
.timed-auction__filter--icon,
.social__links {
  margin-left: 0.5rem;
}

// ml-3
.account__form-group--check-list {
  margin-left: 1rem;
}

// mr-1
.upcoming-auctions-item__icon {
  margin-right: 0.25rem;
}

// mr-2
#inplay__content--lotNumber,
.list-item__badge--lot-number,
.lot__country-flag,
.lot__info--lot-number,
.page-item-count__input {
  margin-right: 0.5rem;
}

// mr-3
.account__cta--cancel,
.auction-downloads__icon,
.listing-downloads__icon,
.registration__action--cancel,
#sale-info__image--auction-logo,
#termsCTA {
  margin-right: 1rem;
}

// mr-n3
.lot__info--description-link {
  margin-right: -1rem;
}

// my-0
.account__form-group--check,
.bid-history__title,
.listing-downloads__heading,
.list-item__heading,
.listing-partial--title {
  margin-top: 0;
  margin-bottom: 0;
}

// my-2
.timed-search-form__input--check {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

// my-3
#accountDetailsForm fieldset,
.account__actions,
.account__form,
.auction__results-count,
.change-password__form,
#contactSeller__input,
.fees-alert,
.forgotten-password__form,
.invoice__item,
.listing-details,
.listing__description--webcast,
.login__actions,
.login__form,
.lot__supplementary--other-listings h2,
.my-sales__nav,
.page__loader,
.page__title--secondary,
#registration__heading,
.registration__actions,
.registration__form,
.registration__spending-limit-group,
.sale-info__heading,
.timed-auction__filter,
.timed-auction__heading,
.timed-auction__alert--no-results,
.upcoming-marketplace-item,
.video__action--show {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

// mx-1
.invoice__badge--collection,
.lot-watch__icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

// mx-2
.form-check-label,
.invoice__badge--payment {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

// mx-3
.listing__card--partial,
.listing-single-nav__item,
.lot__info--bid-count-text,
.lot__info--bid-status {
  margin-left: 1rem;
  margin-right: 1rem;
}

// p-0
#inplay__action--show-gallery,
.list-item__title,
.registration__action--view-terms {
  padding: 0;
}

// p-2
.media--primary-image,
.page-item-count__label,
.upcoming-auctions-item__badge {
  padding: 0.5rem;
}

// p-3
#inplay__alert--actions,
.page__loader {
  padding: 1rem;
}

// pt-0
.lot__actions,
.listings__carousel--primary .media--primary-image {
  padding-top: 0;
}

// pt-3
.auction__results-count,
.timed-search-form,
.upcoming__list--header,
.upcoming-auctions-item__content {
  padding-top: 1rem;
}

// pb-0
.lot__info {
  padding-bottom: 0;
}

// pb-3
#accountDetailsForm fieldset,
.carousel__image-count,
.listing__single--other-actions {
  padding-bottom: 1rem;
}

// px-0
.listing-downloads__list-item,
.upcoming-auctions__list--countries {
  padding-left: 0;
  padding-right: 0;
}

// px-3
.login__aside,
.upcoming-auctions-item__image,
.upcoming-auctions-item__content,
.upcoming__list--tabs {
  padding-left: 1rem;
  padding-right: 1rem;
}

// py-0
.account__category--body,
.account__list-item--category {
  padding-top: 0;
  padding-bottom: 0;
}

// py-1
.upcoming-auctions-item__image {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

// py-3
.auction-download__item,
.auction__lots,
#login__content--create-account,
.login__form,
.lot__supplementary--other-listings,
.registration__text,
.social__container,
.upcoming-marketplaces__list {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.interactive_modal--checkbox-col {
  max-width: 2rem;
}

.auction__filters--reset {
  margin-top: -5px;
  margin-bottom: -5px;
  border: 0;
  font-size: 1.1rem;
}

.lot__heading--category {
  font-weight: bold;
  color: #777;
  text-transform: uppercase;
  font-size: 1rem;
}

.auction-view__toggle {
  display: none;
}

.lot__textual {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .timed-listing--list-layout {
    width: 100%;
    .timed-listing.lot {
      display: flex;
      flex-direction: row;
      .lot__media {
        flex-basis: 400px;
        flex-shrink: 0;
      }
      .lot__media + div {
        flex-basis: 3;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
      .lot__number {
        top: 2rem;
        bottom: auto;
      }
    }
  }
  
  .auction-view__toggle {
    display: inline-block;
    margin-left: 0;
    padding: 0px 6px;
    font-size: 14px;
    &:not(.active) {
      color: #777;
    }
  }
  .auction__results-count > * {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .upcoming-auctions-item__images,
  .lot__supplementary--other-listings {
    .carousel .carousel__slide {
      width: 33.333%;
      min-width: 33.333%;
    }
  }
}

@media screen and (max-width: 576px) {
  .upcoming-auctions-item__images,
  .lot__supplementary--other-listings {
    .carousel .carousel__slide {
      width: 50%;
      min-width: 50%;
    }
  }
}


@media screen and (max-width: 767px) {
  .sticky-top {
    position: relative !important;
  }
}
