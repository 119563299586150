.nav-right > li {
  float: right;
}

.media {
  clear: both;
  img {
    float: left;
  }
  &-body {
    width: auto;
  }
  &-body,
  &-left,
  &-right {
    display: block;
  }
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.btn-group-justified {
  .btn-group + .btn-group > button {
    border-left: 0;
  }
}

.lot__info--summary {
  line-height: 1.42857143;
}

.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.align-baseline {
  vertical-align: baseline;
}

dl.row > dt {
  clear: left;
}

.border-left {
  border-left: 1px solid #eee;
}

.border-top-0 {
  border-top-width: 0 !important;
}

.border-bottom-0 {
  border-bottom-width: 0 !important;
}
