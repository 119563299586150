@import '~@algolia/autocomplete-theme-classic/dist/theme.min';

.aa-Panel {
  z-index: 99999999;
  h5.searchListItem__title {
    font-weight: bold;
  }
}

.search-container .aa-Form,
.aa-DetachedOverlay .aa-Form,
.search-container .aa-DetachedSearchButton,
.search-container .aa-DetachedFormContainer {
  border: 1px solid #ddd;
  background-color: #fcfcfc;
}
.search-container .aa-Form:focus-within,
.aa-DetachedOverlay .aa-Form:focus-within {
  border-color: #ccc;
  box-shadow: rgba(30, 30, 30, 0.2) 0 0 0 1px;
}
.search-container .aa-SubmitButton {
  outline: none;
}
.search-container .aa-Label svg,
.search-container svg.aa-SubmitIcon,
.aa-DetachedOverlay svg.aa-SubmitIcon {
  color: #aaa;
}
.aa-Panel .aa-Item {
  padding: 3rem 1rem;
  border-bottom: 1px solid #eee;
}
