.bidjs-app {
  @import 'colors';
  @import 'layout';
  
  @import '../app';
}

@import '~react-inner-image-zoom/lib/InnerImageZoom/styles';
@import '../datepicker';
@import '../search';

.bidjs-app,
.aa-Panel {
  .ratio-container {
    position: relative;
    width: 100%;
  }
  .ratio-container--holder {
    content: '';
    display: block;
    height: 0;
    width: 100%;
  }
  .ratio-container > *:not(.ratio-container--holder) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  .ratio--flex-wrapper {
    padding-bottom: 75%;
    position: relative;
    width: 100%;
  }

  .lot__media--missing {
    color: #bbb;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    svg {
      font-size: 4rem;
      margin: 1rem;
    }
  }
}
